import _get from 'lodash/get'
import invert from 'lodash/invert'

export * from 'utils/links'
export * from 'utils/format'
export * from 'utils/params'
export * from 'utils/fetch'
export * from 'utils/resources'
export * from 'utils/misc'
export * from 'utils/NestedSelector'
export * from 'utils/theme'
export * from 'utils/rbac'

const hostMapping = {
  "admin01.expeto.io": "test01.expeto.io",
  "admin02.expeto.io": "test02.expeto.io",
  "admin03.expeto.io": "test03.expeto.io",
  "admin05.expeto.io": "test05.expeto.io",
  "admin.expeto.io": "api.expeto.io",
};

function getRedirectHost(host) {
  return _get(hostMapping, host, host)
}

function redirectToAdminHost(host, pathname, search) {
  if (invert(hostMapping)[host]) {
    window.location = "https://" + invert(hostMapping)[host] + pathname + search
  }
}
const redirectHost = getRedirectHost(window.location.host);
redirectToAdminHost(window.location.host, window.location.pathname, window.location.search);
export const BASE_URL = window.location.port === "3000"
    ? "http://localhost:8222"
    : window.location.protocol + "//" + redirectHost;

// returns a copy of the string
export const copyString = str => {
  if(str.constructor.name !== "String") throw new Error('Pass a string')
  return ('X' + str).slice(1)
}